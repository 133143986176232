import { createApp } from 'vue';

import app from './app.vue';
import router from './router';
import store from './store';
import utils from './utils';
//import request from './utils/httpRequest';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import request from './utils/request';
import appImg from '@/components/app-img';
import appButton from '@/components/app-button';
import vueSeamlessScroll from 'vue3-seamless-scroll';
import 'animate.css';

// 日期组件中文
import 'dayjs/locale/zh-cn';
import dayjs from 'dayjs';
dayjs.locale('zh-cn');
dayjs.extend(require('dayjs/plugin/localeData'));

import '@/styles/global.scss';

const monitor = createApp(app);
monitor
  .use(store)
  .use(router)
  .use(ElementPlus, {
    locale: zhCn,
    size: 'large',
  })
  .use(vueSeamlessScroll)
  .component('app-img', appImg)
  .component('app-button', appButton)
  .mount('#app');

monitor.config.globalProperties.$utils = utils;
monitor.config.globalProperties.$request = request;
