/**
 * @type {import('vue-router').RouteRecord[]} routes
 */
export const routes = [
  {
    path: '/',
    meta: {
      title: '主页',
    },
    component: () => import('@/layouts/layouts.vue'),
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('@/views/home/home.vue'),
        meta: {
          auth: {
            required: true,
          },
          backgroundImg: 'https://imgjixi.xlvcheng.com/dataScreen/ge-bg.png',
        },
      },
      {
        path: '/merchant',
        name: 'merchant',
        component: () => import('@/views/merchant/merchant.vue'),
        meta: {
          auth: {
            required: true,
          },
        },
      },
      {
        path: '/touristSource',
        name: 'touristSource',
        component: () => import('@/views/touristSource/touristSource.vue'),
        meta: {
          auth: {
            required: true,
          },
        },
      },
    ],
  },

  {
    path: '/:catchAll(.*)',
    component: () => import('@/views/common/400.vue'),
    name: '400',
  },
  {
    path: '/login',
    component: () => import('@/views/common/login.vue'),
    name: 'login',
  },
];
